import clsx from 'clsx';

import {useMatches} from '@remix-run/react';
import LinkButton from '~/components/elements/LinkButton';
import HeroContent from '~/components/heroes/HeroContent';
import type {SanityHeroHome} from '~/lib/sanity';

import {DEFAULT_LOCALE} from '~/lib/utils';

// import Swiper core and required modules
import {Navigation, Pagination, Autoplay} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import {getSeoMeta} from '@shopify/hydrogen';

type Props = {
  hero: SanityHeroHome;
};

export default function HomeHero({hero}: Props) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const lang = selectedLocale.language;

  let title = '';
  if (lang === 'DE') {
    title = hero.title?.de;
  } else if (lang === 'EN') {
    title = hero.title?.en;
  }

  const sliderData = hero.sliderItem;

  return (
    <div>
      <div className={clsx('relative mt-16 pt-2', 'md:mt-28 md:pt-0')}>
        <div className="relative w-full">
          {title && (
            <h1 className="mb-10 max-w-[50rem] px-4 pt-1 text-3xl md:px-8 md:pt-2 md:text-3xl">
              {title}
            </h1>
          )}
          {hero.link && (
            <>
              <LinkButton link={hero.link} />
            </>
          )}
        </div>

        <div className="relative w-full">
          <Swiper
            navigation={true}
            pagination={{
              type: 'bullets',
              clickable: true,
            }}
            loop={true}
            autoplay
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 2,
              },
            }}
          >
            {sliderData &&
              sliderData.map((item: any, index: number) => {
                const title = lang === 'DE' ? item.title.de : item.title.en;
                const content = item.content || null;
                const landscape = item.landscape;

                return (
                  <SwiperSlide
                    key={index}
                    className={`  ${
                      landscape === false ? 'slide50' : 'slide100'
                    }  `}
                  >
                    <div className="relative w-full ">
                      <div className="center absolute top-0 z-10 w-full">
                        <h2 className="w-full text-center text-xl">{title}</h2>
                      </div>

                      {content && (
                        <div className="sliderExtra">
                          <HeroContent content={content} layout={100} />
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
